import { graphql } from 'gatsby';
import * as React from 'react';
import Pages from 'components/Pages';
import PortfolioDetails from '../../components/pages/portfolio/PortfolioDetails';

const PortfolioDetailsTemplate = ({ data }) => {
  return (
    <Pages>
      <PortfolioDetails
        company={data.portfolioJson}
        allCompanies={data.allPortfolioJson.edges}
      />
    </Pages>
  );
};

export default PortfolioDetailsTemplate;

export const query = graphql`
  query ($slug: String) {
    portfolioJson(slug: { eq: $slug }) {
      index
      name
      slug
      listImage
      detailsImage
      descriptionImage
      website
      area
      location
      date
      description
    }
    allPortfolioJson {
      edges {
        node {
          index
          name
          slug
          listImage
          detailsImage
          descriptionImage
          website
          area
          location
          date
          description
        }
      }
    }
  }
`;
