/* eslint-disable new-cap */
import React, { useState, useEffect } from 'react';
import FadeInLeft from 'components/animation/FadeInLeft';
import ReactHtmlParser from 'react-html-parser';
import DetailsPageContainer from '../../DetailsPageContainer';
import PropTypes from 'prop-types';

const CompanyDetailsContainer = ({ title, description, hyperlink, href }) => (
  <div className="flex flex-row mb-3">
    <p className="font-bold text-sm w-[45%] sm:w-[170px]">{title}</p>
    {hyperlink ? (
      <a
        href={href}
        target="_blank"
        rel="noreferrer"
        className="line-clamp-1 w-[55%] sm:w-[fit-content]"
      >
        <p className="text-sm underline hover:opacity-50 duration-300 cursor-pointer">
          {description}
        </p>
      </a>
    ) : (
      <p className="text-sm w-[55%] sm:w-[fit-content]">{description}</p>
    )}
  </div>
);

const PortfolioDetails = ({ company, allCompanies }) => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  useEffect(() => {
    allCompanies?.forEach((c, i) => {
      if (c?.node?.slug === company?.slug) {
        setSelectedIndex(i);
      }
    });
  }, [allCompanies, company?.slug]);

  return (
    <DetailsPageContainer
      pageTitle={company?.name}
      backButtonSlug="/portfolio"
      nextButtonSlug={`/portfolio/${
        allCompanies[
          selectedIndex === allCompanies.length - 1 ? 0 : selectedIndex + 1
        ]?.node?.slug
      }`}
      nextPageTitle={
        allCompanies[
          selectedIndex === allCompanies.length - 1 ? 0 : selectedIndex + 1
        ]?.node?.name
      }
      childrenLeftSection={
        <>
          <div
            className="col-span-8 lg:col-span-3 bg-[#131313] h-[280px] sm:h-[300px]
            w-screen sm:w-full translate-x-[-1.25rem] sm:translate-x-0 xl:hidden"
          >
            <FadeInLeft>
              <img
                src={company?.detailsImage}
                alt="logo-details"
                className="object-contain w-full h-[280px] sm:h-[300px]"
              />
            </FadeInLeft>
          </div>
          <div className="col-span-8 lg:col-span-3 hidden xl:block">
            <div
              className="bg-[#131313] w-[37vw] 2xl:w-[570px] h-[420px] 2xl:h-[470px]
              absolute left-0 translate-y-[-5rem]"
            >
              <FadeInLeft>
                <img
                  src={company?.detailsImage}
                  alt="logo-details"
                  className="object-contain w-full w-[37vw] 2xl:w-[570px] h-[420px] 2xl:h-[470px]"
                />
              </FadeInLeft>
            </div>
          </div>
        </>
      }
      childrenRightSection={
        <>
          <div className="my-8">
            <CompanyDetailsContainer
              title="Website"
              description={company?.website}
              hyperlink
              href={company?.website}
            />
            <CompanyDetailsContainer title="Area" description={company?.area} />
            <CompanyDetailsContainer
              title="Location"
              description={company?.location}
            />
            <CompanyDetailsContainer
              title="Date of Entry"
              description={company?.date}
            />
          </div>
          <div className="text-sm sm:text-[20px] font-thin leading-relaxed">
            {ReactHtmlParser(company?.description)}
          </div>
          <img
            src={company?.descriptionImage}
            alt="company"
            className="object-cover w-full h-[450px] mt-10 hidden lg:block"
          />
        </>
      }
    />
  );
};

CompanyDetailsContainer.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  hyperlink: PropTypes.bool,
  href: PropTypes.string,
};

PortfolioDetails.propTypes = {
  company: PropTypes.any,
  allCompanies: PropTypes.any,
};

export default PortfolioDetails;
