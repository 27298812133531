import React from 'react';
import LEFT_ARROW_ICON from 'images/arrow-left.svg';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import CustomContainer from './CustomContainer';

const BackButton = ({ linkTo, mobile }) => (
  <Link to={linkTo}>
    <div
      className={`${
        mobile ? 'flex lg:hidden' : 'hidden lg:flex'
      } items-center cursor-pointer hover:opacity-50 duration-500 ${
        mobile && 'mt-10'
      }`}
    >
      <img
        src={LEFT_ARROW_ICON}
        alt="back-arrow"
        className="w-[35px] sm:w-[40px] mr-2"
      />
      <p className="text-sm sm:text-base font-thin">Back</p>
    </div>
  </Link>
);

const NextButton = ({ linkTo, title }) => (
  <Link to={`${linkTo}`}>
    <div className="flex flex-col text-center cursor-pointer items-center hover:opacity-50 duration-500">
      <div className="flex flex-row items-center">
        <p className="text-sm sm:text-base font-thin">Next</p>
        <img
          src={LEFT_ARROW_ICON}
          alt="next-arrow"
          style={{ transform: 'rotate(180deg)' }}
          className="w-[35px] sm:w-[40px] ml-2"
        />
      </div>
      <div className="text-[32px] sm:text-lg sm:mt-3 leading-snug">{title}</div>
    </div>
  </Link>
);

const DetailsPageContainer = ({
  pageTitle,
  backButtonSlug,
  nextButtonSlug,
  nextPageTitle,
  childrenLeftSection,
  childrenRightSection,
}) => (
  <div className="bg-brand-black text-brand-white font-light min-h-screen pt-[70px]">
    <CustomContainer className="pb-16 relative">
      {/* for mobile */}
      <BackButton mobile linkTo={backButtonSlug} />
      <div className="text-[32px] lg:hidden mt-3">{pageTitle}</div>
      {/* */}
      <div className="grid grid-cols-8 col-span-2 mt-3 mt-5 lg:mt-0">
        {childrenLeftSection}
        <div className="col-span-8 lg:col-span-5 lg:mt-8 lg:ml-8">
          <BackButton linkTo={backButtonSlug} />
          <div className="text-lg hidden lg:block">{pageTitle}</div>
          {childrenRightSection}
        </div>
      </div>
      <hr className="my-16 lg:my-24" />
      <div className="flex flex-col items-center">
        <NextButton linkTo={nextButtonSlug} title={nextPageTitle} />
      </div>
    </CustomContainer>
  </div>
);

BackButton.propTypes = {
  linkTo: PropTypes.string,
  mobile: PropTypes.bool,
};

NextButton.propTypes = {
  linkTo: PropTypes.string,
  title: PropTypes.string,
};

DetailsPageContainer.propTypes = {
  pageTitle: PropTypes.string,
  backButtonSlug: PropTypes.string,
  nextButtonSlug: PropTypes.string,
  nextPageTitle: PropTypes.string,
  childrenLeftSection: PropTypes.node,
  childrenRightSection: PropTypes.node,
};

export default DetailsPageContainer;
